<template>

    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <DataTable :value="billlingplanlist" :lazy="true" :paginator="true" :rows="30"
                    :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" class="p-datatable-users"
                    data-key="bb1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Billing Plan</h5>
                        </template>
                        <template v-slot:right>
                            <Button label="Add Plan" icon="pi pi-plus" class=" p-mr-2" @click="addFundDialogOpen" />
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <Column header="Sr." headerStyle="width: 4%">
                        <template #body="{ index }">
                            <div>{{ genrateSrNo(index) }}</div>
                        </template>
                    </Column>
                    <Column field="name" header="Plan Name" headerStyle="width: 40%">
                        <template #body="{data}">
                            <div class="p-text-capitalize">{{ data.bb2 ? data.bb2 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <!-- <Column header="Charges" headerStyle="width: 11%">
                        <template #body="{data}">
                            <span v-if="data.bb5 == 1" :class="'plan-badge status-free'">Free</span>
                            <span v-if="data.bb5 == 2" :class="'plan-badge status-paid'">Paid</span>
                        </template>
                    </Column> -->
                    <Column header="Type" headerStyle="width: 12%">
                        <template #body="{data}">
                            <span v-if="data.bb6 == 1" :class="'plan-type-badge status-standard'">Standard</span>
                            <span v-if="data.bb6 == 2" :class="'plan-type-badge status-premium'">Premium</span>
                        </template>
                    </Column>
                    <Column header="Monthly Cost" headerStyle="width: 12%" >
                        <template #body="{data}">
                            <div>&#8377; {{ formatCurrency(data.bb3 ? data.bb3 : '0') }}</div>
                        </template>
                    </Column>
                    <Column header="View" headerStyle="width: 15%">
                        <template #body="{data}">
                            <Button label="Components" class="p-button-link" @click="planComponentRedirect(data)" />
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 10%" >
                        <template #body="{data}">
                            <Button icon="pi pi-pencil" class="p-button-rounded " @click="editplandata(data.bb1)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
    <!-- add billing plan dialog start here -->
    <Dialog v-model:visible="addClientPlanStatus" :style="{ width: '800px' }" :modal="true" class="p-fluid">
        <Toast />
        <template #header>
            <span class="p-dialog-title" v-if="editheaderflag == 0">Add Plan</span>
            <span class="p-dialog-title" v-if="editheaderflag != 0">Edit Plan</span>
        </template>
        <div style="position: relative" v-if="!showloaderpage">
            <div class="p-pt-2 p-px-3">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-12">
                        <label for="planname">
                            Plan Name
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <InputText id="planname" class="p-text-capitalize" v-model.trim="planname" required="true"
                            maxlength="20" autofocus :class="{ 'p-invalid': submitted && !planname }" />
                        <small class="p-invalid p-error" v-if="v$.planname.$error">{{
                        v$.planname.$errors[0].$message
                        }}</small>
                    </div>
                    <!-- <div class="p-field p-col-12 p-md-4">
                        <label>Plan Charges <span class="p-invalid p-error"> *</span></label>
                        <div class="p-field-radiobutton">
                            <RadioButton id="charges_free" name="option" v-bind:value="1" v-model="plancharges"
                                :checked="plancharges == 1" />
                            <label for="charges_free" class="p-mr-2">Free</label>
                            <RadioButton id="charges_paid" name="option" v-bind:value="2" v-model="plancharges"
                                :checked="plancharges == 2" />
                            <label for="charges_paid" class="p-mr-2">Paid</label>
                        </div>
                        <small class="p-invalid p-error" v-if="v$.plancharges.$error">{{
                        v$.plancharges.$errors[0].$message
                        }}</small>
                    </div> -->
                    <div class="p-field p-col-12 p-md-4">
                        <label>Plan Type <span class="p-invalid p-error"> *</span></label>
                        <div class="p-field-radiobutton">
                            <RadioButton id="type_standard" name="option" v-bind:value="1" v-model="plantype"
                                :checked="plantype == 1" />
                            <label for="type_standard" class="p-mr-2">Standard</label>
                            <RadioButton id="type_premium" name="option" v-bind:value="2" v-model="plantype"
                                :checked="plantype == 2" />
                            <label for="type_premium" class="p-mr-2">Premium</label>
                        </div>
                        <small class="p-invalid p-error" v-if="v$.plantype.$error">{{ v$.plantype.$errors[0].$message
                        }}</small>
                    </div>
                    <div class="p-field p-col-12 p-md-4">
                        <label for="monthlycost">
                            Monthly Cost
                            <span class="p-invalid p-error">*</span>
                        </label>
                        <InputText id="monthlycost" class="p-text-capitalize" v-model.trim="monthlycost" @keypress="onlyNumber" required="true"
                            maxlength="8" autofocus :class="{ 'p-invalid': submitted && !monthlycost }" />
                        <small class="p-invalid p-error" v-if="v$.monthlycost.$error">{{
                        v$.monthlycost.$errors[0].$message
                        }}</small>
                    </div>

                </div>
            </div>
        </div>
        <div v-if="showloaderpage" style="height: 200px; position: relative">
            <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
            </div>
        </div>
        <template #footer>
            <div>
                <Button v-if="!showLoader" label="Submit" icon="pi pi-check" class="p-button-text p-ml-auto"
                    @click="addNewPlan" style="width: 90px"></Button>
                <Button v-show="showLoader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                    style="width: 90px"></Button>
            </div>
        </template>
    </Dialog>
    <!-- add billing plan dialog end here -->
</template>
<script>
import ApiService from '../service/ApiService';
import useValidate from '@vuelidate/core';
import router from '@/router';
import { required, helpers, numeric, maxLength, minLength } from '@vuelidate/validators';
export default {
    inject: ['global'],
    data() {
        return {
            v$: useValidate(),
            billlingplanlist: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            addClientPlanStatus: false,
            planfor: '',
            planforList: [],
            plancharges: '',
            plantype: '',
            monthlycost: '',
            planname: '',
            showLoader: false,
            submitted: false,
            plansdata: '',
            planid: 0,
            editheaderflag: 0,
            showloaderpage: false,
            pushplanid: '',
            globalexitedPlanId: '',
            globalexitedPlanLabel: '',
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.loading = true;
        this.getallplans();
        if(localStorage.globalexitedPlanLabel) this.globalexitedPlanLabel = localStorage.globalexitedPlanLabel;
        if(localStorage.globalexitedPlanId) this.globalexitedPlanId = localStorage.globalexitedPlanId;
    },
    watch: {
        globalexitedPlanLabel(billplanname){
            localStorage.globalexitedPlanLabel = billplanname;
        },
        globalexitedPlanId(billplanid){
            localStorage.globalexitedPlanId = billplanid;
        }
    },
    validations() {
        return {
            // plancharges: { required: helpers.withMessage('Please select plan charges', required) },
            plantype: { required: helpers.withMessage('Please select plan type', required) },
            monthlycost: { required: helpers.withMessage('Please enter monthly cost', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(3), maxLength: maxLength(10) },
            planname: { required: helpers.withMessage('Please enter plan name', required) },
        };
    },
    methods: {
        getallplans(ev) {
            this.loading = true;
            this.ApiService.getallplans(ev).then((data) => {
                if (data.status == 200) {
                    this.billlingplanlist = data.data;
                    this.totalRecords = data.count;
                } else {
                    this.billlingplanlist = '';
                    this.totalRecords = 0;
                }
                this.loading = false;
            });
        },

        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.clientlist({ page_no: event.page });
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        addFundDialogOpen() {
            this.addClientPlanStatus = true;
            this.planname = '';
            this.monthlycost = '';
            this.plancharges = 2;
            this.plantype = '';
            this.editheaderflag = 0;

        },

        editplandata(item) {
            this.addClientPlanStatus = true;
            this.planname = '';
            this.monthlycost = '';
            this.plancharges = 2;
            this.plantype = '';
            this.getplandetails(item);
            this.editheaderflag = 1;
        },

        addNewPlan() {
            
            this.submitted = true;
            this.errormsg = '';
            let fields = {};
            fields['bb1'] = this.planid;
            fields['bb5'] = this.plancharges;
            fields['bb6'] = this.plantype;
            fields['bb3'] = this.monthlycost;
            fields['bb2'] = this.planname;
            this.v$.$validate();
            if (!this.v$.$error) {
                this.showLoader = true;
                this.ApiService.addNewPlan(fields).then((items) => {
                    if (items.status == 200) {
                        this.loading = false;
                        this.showLoader = false;
                        var successMsg = items.message;
                        this.addClientPlanStatus = false;
                        this.$toast.add({ severity: 'success', summary: 'Plan Added Successful', detail: successMsg, life: 3000 });
                        this.getallplans();
                    } else {
                        this.loading = false;
                        var errorMsg = items.message;
                        this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                    }
                });
            }
        },

        getplandetails(item) {
            this.showloaderpage = true;
            let field = {};
            field.bb1 = item;
            this.ApiService.getplandetails(field).then((data) => {
                if (data.status == 200) {
                    this.plansdata = data.data.records;
                    this.planid = this.plansdata.bb1;
                    this.planname = this.plansdata.bb2;
                    this.monthlycost = this.plansdata.bb3;
                    this.plancharges = this.plansdata.bb5;
                    this.plantype = this.plansdata.bb6;
                    this.showloaderpage = false;
                } else {
                    this.plansdata = '';
                    this.showloaderpage = false;
                    this.addClientPlanStatus = false;
                }

            });
        },

        planComponentRedirect(e) {
            router.push({
                name: "billingplancomponent",
                params: { planrowId: e.bb1 },
            });
            this.globalexitedPlanId = e.bb1;
            this.globalexitedPlanLabel = e.bb2;
            // window.open(routeData.href, "_self");
               
        },
        formatCurrency(value) {
            return value.toLocaleString({ style: 'currency', currency: 'INR' });
        },
    },
};
</script>
<style scoped lang="scss">
.plan-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-free {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-paid {
        background: #ffd8b2;
        color: #805b36;
    }
}

.plan-type-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-standard {
        background: #b3e5fc;
        color: #23547b;
    }

    &.status-premium {
        background: #eccfff;
        color: #694382;
    }
}
</style>
